import store from '../store'
import queryString from 'query-string'

export default [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../views/faturamento/PosicaoFinanceira.vue')
      },
      {
        path: '/PosicaoFinanceira',
        name: 'posicaoFinanceira',
        component: () => import(/* webpackChunkName: "about" */ '../views/faturamento/PosicaoFinanceira.vue')
      },
      {
        path: '/FaturamentoParcial',
        name: 'faturamentoParcial',
        component: () => import(/* webpackChunkName: "about" */ '../views/faturamento/FaturamentoParcial.vue')
      },
      {
        path: '/Rastreamento',
        name: 'rastreamento',
        component: () => import(/* webpackChunkName: "about" */ '../views/rastreamento/Rastreamento.vue')
      },
      {
        path: '/login',
        redirect: (to) => {
          const url_redirect = store.state.url_auth + '?' + queryString.stringify(to.query)
          window.location.href = url_redirect
        }
      },
      {
        path: '/logout',
        redirect: (to) => {
          store.dispatch('clear_token')
          const url_redirect = store.state.url_auth + '?' + queryString.stringify(to.query)
          window.location.href = url_redirect
        }
      },
      {
        path: '*',
        component: () => import(/* webpackChunkName: "about" */ '../views/faturamento/PosicaoFinanceira.vue')
      }
]