import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import VueCookies from 'vue-cookies'
import moment from 'moment';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate'
import VueMoment from 'vue-moment'
import momentVue from 'moment-timezone'
import Snotify from 'vue-snotify';
import VueHtmlToPaper from 'vue-html-to-paper';

const optionsPrint = {
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900',
    'https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css',
    'https://fonts.googleapis.com/css?family=Material+Icons',
    'https://cdn.jsdelivr.net/npm/vuetify@2.5.8/dist/vuetify.min.css',
    'imprimir.css'
  ],
  timeout: 500, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.config.productionTip = false
Vue.prototype.moment = moment;
Vue.use(VueAxios, axios)
Vue.use(Notifications)
Vue.use(VueCookies)
Vue.use(Snotify)
Vue.use(Vuelidate)
Vue.use(VueMoment, {
  momentVue,
})
Vue.use(VueTheMask);
Vue.use(VueHtmlToPaper, optionsPrint);


VueCookies.config('4d')

// region global filters

Vue.filter('formatDateBRFull', function (value) {
  if (!value) { return ''; }
  if (value == '') { return ''; }
  if (moment(value, 'DD-MM-YYYY').isValid()) {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY hh:mm');
    }
  } else {
    return '';
  }
})


Vue.filter('formatDateBRMin', function (value) {
  if (!value) { return ''; }
  if (value == '') { return ''; }
  if (moment(value, 'DD-MM-YYYY').isValid()) {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY');
    }
  } else {
    return '';
  }
})

Vue.filter('FormatMoney', function (number, decPlaces, decSep, thouSep) {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
    decSep = typeof decSep === "undefined" ? "," : decSep;
  thouSep = typeof thouSep === "undefined" ? "." : thouSep;
  var sign = number < 0 ? "-" : "";
  var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
})




  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')