<template>
  <v-main>
      <v-container fluid>
        <div>
          <v-fade-transition mode="out-in">
            <router-view />
          </v-fade-transition>
        </div>
      </v-container>
  </v-main>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "View",
    };
  },
};
</script>

<style>
#core-view {
  padding-bottom: 100px;
}
</style>
