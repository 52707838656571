<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    permanent
    mini-variant
    clipped
    expand-on-hover
    dark
    color="blue darken-3" 
    :mini-variant-width="60"
  >
   <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="logo" alt="logo"></v-img>
        </v-list-item-avatar>
        <v-list-item link>
          <v-list-item-content color="white">
            <v-list-item-title>{{ navtitle }}</v-list-item-title>
            <v-tooltip
              right
              allow-overflow="true"
              fixed="false"
              min-width="550"
            >
              <template v-slot:activator="{ on }">
                <v-list-item-subtitle v-on="on">{{
                  navsubtitle
                }}</v-list-item-subtitle>
              </template>
              <span>{{ navsubtitle }}</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </v-list-item>
    </v-list> 
    <v-list nav>
      <v-divider />
      <v-list-item
        v-for="(link, i) in links"
        :id="link.name"
        :key="i"
        :to="link.to"  
      >
        <v-list-item-action>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-title v-text="link.text" />
      </v-list-item>
      <v-divider />
      <v-list-item
        id="logout"
        @click="logout"
        class="v-list-item"
      >
        <v-list-item-action>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-action>
        <v-list-item-title v-text="`Sair`" />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from "vuex";
import jwt_decode from "jwt-decode";

export default {
  data: () => ({
    navtitle: "",
    navsubtitle: "",
    logo: "logo_48.ico",
    links: [
      {
        to: "/PosicaoFinanceira",
        icon: "mdi-cash-fast",
        text: "Posição Financeira",
      },
      {
        to: "/FaturamentoParcial",
        icon: "mdi-cash-marker",
        text: "Faturamento Parcial",
      },
      {
        to: "/Rastreamento",
        icon: "mdi-radar",
        text: "Rastreamento",
      },
    ],
  }),
  mounted() {
    this.carregaDadosUsuario(
      jwt_decode(this.axios.defaults.headers.common["Authorization"])
    );
  },
  computed: {
    ...mapState("app", ["image", "color"]),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    items() {
      return this.$t("Layout.View.items");
    },
  },
  methods: {
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    logout() {
      this.$router.push({
        path: "/logout",
        query: {
          callback: window.location.origin,
        },
      });
    },
    carregaDadosUsuario(token) {
      if (token.claim_emailCobranca != undefined) {
        //this.navtitle = token.claim_innerpessoa + " - " + token.claim_nome;
        this.navtitle = token.claim_nome;
        this.navsubtitle = token.claim_emailCobranca;
      } else {
        setTimeout(this.carregaDadosUsuario(token), 300); // try again in 300 milliseconds
      }
    },
  },
};
</script>

<style lang="scss">
#app-drawer {
  #list-avatar {
    margin-top: 0px;
  }

}
</style>
