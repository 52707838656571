<template>
  <v-app v-show="isAuthenticated">
    <core-notifications />
    <core-drawer />
    <core-view> </core-view>
  </v-app>
</template>

<script>
//import CoreToolbar from "@/components/core/Toolbar.vue";
import CoreDrawer from "@/components/core/NavigationDrawer.vue";
import CoreView from "@/components/core/View.vue";
import CoreNotifications from "@/components/core/SNotification.vue";
import { mapGetters } from "vuex";
import jwt_decode from "jwt-decode";

export default {
  components: {
    CoreDrawer,
    CoreView,
    CoreNotifications,
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {},
  mounted() {
    this.$router.replace({
      name: this.$route.name,
      params: this.$route.params,
    });

    var context = this;
    this.axios.interceptors.response.use(
      function (config) {
        return config;
      },
      function (error) {
        var stringError = JSON.stringify(error);

        // const requestError = error.request;
        if (stringError.includes("Network Error")) {
          context.$snotify.error(
            "Favor Verificar sua conexão com a internet",
            "Informação",
            {
              timeout: 3000,
              showProgressBar: true,
              titleMaxLength: 200,
              bodyMaxLength: 200,
              closeOnClick: true,
              position: "centerCenter",
              preventDuplicates: true
            }
          );
          return;
        }

        if (error.response.status !== 401) {
          //informações gerais de retorno no momento achei melhor deixar no código interno da pesquisa
          
          /*context.$snotify.info(error.response.data, "Informação", {
            timeout: 3000,
            showProgressBar: true,
            titleMaxLength: 200,
            bodyMaxLength: 200,
            closeOnClick: true,
            position: "centerCenter",
            preventDuplicates: true
          });*/
        } else if (error.response.status === 401) {
          //relogar
          //store.dispatch('auth/reset')
          //store.dispatch('app/error', 'You have been logged out.')
        } else {
          context.$store.dispatch("clear_token");
          context.$router.push({
            path: "/login",
            query: {
              callback: window.location.href,
            },
          });
        }
        return Promise.reject(error);
      }
    );

    try {
      this.$store.state.tokenDecoded = jwt_decode(
        this.axios.defaults.headers.common["Authorization"]
      );
    } catch (e) {
      alert(e);
    }

    this.$router.push("PosicaoFinanceira");
  },
};
</script>

<style lang="scss">
@import "@/styles/index.scss";
@import "~vue-snotify/styles/material";

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
