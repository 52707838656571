import Vue from 'vue'
//import VueJwtDecode from 'vue-jwt-decode'

export default {
  init({ commit }) {
    /* eslint-disable */
    if ($cookies.isKey('user-token')) {
      var token = $cookies.get('user-token')
      Vue.axios.defaults.headers.common['Authorization'] = "Bearer " + token.token
      commit('SET_TOKEN', token)
    }
    /* eslint-enable */
  },
  set_token({ commit }, token) {
    /* eslint-disable */
    $cookies.set('user-token', JSON.stringify(token))
    /* eslint-enable */
    Vue.axios.defaults.headers.common['Authorization'] = "Bearer " + token.token
    commit('SET_TOKEN', token)
  },
  clear_token({ commit }) {
    /* eslint-disable */
    $cookies.remove('user-token')
    Vue.axios.defaults.headers.common['Authorization'] = ""
    commit('SET_TOKEN', {})
    /* eslint-enable */
  }
}
