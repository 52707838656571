import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import paths from './paths'

Vue.use(VueRouter)

const routes = paths

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    var { token } = to.query
    store.dispatch('set_token', { token })
    delete to.token
  }
  if (!store.getters.isAuthenticated) {
    store.dispatch('init')
  }
  if (!store.getters.isAuthenticated) {
    if (to.matched.some(record => !record.meta.nonRequiresAuth)) {
      next({
        path: '/login',
        query: { 
          callback: window.location.href
        }
      })
    } else {
      next()
    }
  }
  else {
    next()
  }
})

export default router
